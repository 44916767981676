import {BrowserRouter, Route, Routes} from "react-router-dom";

import {CircularProgress} from "@mui/material";

// import routes from "./config/routes";
import Home from "../src/screens/Home";
import useAuth from "./hooks/useAuth";
import Center from "./components/utils/Center";
import ObjectFound from "./screens/ObjectFound";
import Conversation from "./screens/Conversation";
import SignUp from "./screens/SignUp";
import ToastsProvider from "./hooks/useAppToast";


function App() {
  const {loading} = useAuth();

  if (loading) {
    return (
      <Center>
        <CircularProgress
          sx={{
            color: "white",
          }}
        />
      </Center>
    );
  }

  return (
    <div>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <ToastsProvider >
          <Routes>
            <Route path="/conversation/:id" element={<Conversation />} />
            <Route path="/:code" element={<ObjectFound />} />
            <Route path="/" element={<Home />} />
            <Route path="/signup" element={<SignUp />} />
          </Routes>
        </ToastsProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
